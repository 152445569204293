import { GeneralParameters } from "#imports"

import { BaseApiService, KyOptions } from "./api.service"

class CommonServiceRaw extends BaseApiService {
  getCityById = async (cityId: string, config: KyOptions = {}) => {
    if (!cityId) return
    return this.getData(await this.instance.get("/address/city/" + cityId, config))
  }

  getCities = async (cityString: string) => {
    if (!cityString) return

    return this.getData(await this.instance.get("/address/city/", { params: { city: cityString } }))
  }

  getOccupations = async (config: KyOptions = {}) => {
    return this.getData(await this.instance.get("/occupations", config))
  }

  getStreetById = async (addressId: string, config: KyOptions = {}) => {
    if (!addressId) return

    return this.getData(await this.instance.get("/address/" + addressId, config))
  }

  getCityAddresses = async (cityNum: number | string, streetString: string) => {
    if (!cityNum || !streetString) return

    return this.getData(
      await this.instance.get(`/address/city/${cityNum}/street`, { params: { street: streetString } })
    )
  }

  /** Exporting a singleton object to entire app */
  getGeneralParameters = async (): Promise<GeneralParameters> => {
    return this.getData(await this.instance.get("/generalparameters"))
  }

  getProducts = async (config: KyOptions = {}) => {
    return this.getData(await this.instance.get("products", config))
  }
}

export const CommonService = new CommonServiceRaw()
